<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-playlist-group"></i>{{ $t("Edit Playlist Group") }}
    </h2>

    <v-card class="mt-4 rounded-t-xl round-16 px-6 py-3">
      <v-card-title class="sub-title font-weight-bold">{{$t('NEW GROUP')}}</v-card-title>
      <v-card-text>
        <div class="d-flex align-baseline">
          <div class="d-inline-flex">
            <v-select
              v-model="form.model"
              :label="$t('Model') + ' *'"
              :items="defaultModel"
              class="form-select"
              outlined
              dense
              hide-details
              style="width: 250px"
            >
            </v-select>
            <v-text-field
              v-model="form.title"
              persistent-placeholder
              :placeholder="$t('Input title')"
              :label="$t('Title' + ' *')"
              class="form-input ml-3"
              outlined
              hide-details
              dense
              clearable
              style="width: 150px"
            >
            </v-text-field>
            <v-text-field
              v-model="form.category"
              persistent-placeholder
              :placeholder="$t('Input category')"
              :label="$t('Category')"
              class="form-input ml-3"
              outlined
              dense
              hide-details
              clearable
              style="width: 150px"
            >
            </v-text-field>
          </div>
          <div class="ml-auto">
            <v-btn @click="is_play ? confirmDialog = true : editPlaylist()" text class="btn w-110"
              >{{ $t("Save") }}
            </v-btn>
            <v-btn
              @click="cancelNewPlaylist()"
              text
              class="btn w-110 btn-danger ml-3"
              >{{ $t("Cancel") }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="bg-basic pa-9">
      <v-layout column>
        <v-row no-gutters>
          <v-col cols="5" class="playlist-group-time">
            <div class="d-flex align-center">
              <div class="mr-8">
                <v-slider
                  v-model="timeSlider"
                  vertical
                  class="playslider-vertical"
                >
                  <template v-slot:append>
                    <v-btn icon small @click="decrement"
                      ><img src="@/assets/img/ico-play-up.png"
                    /></v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn icon @click="increment"
                      ><img src="@/assets/img/ico-play-down.png"
                    /></v-btn>
                  </template>
                </v-slider>
              </div>
              <div class="playlist-group-timeline">
                <perfect-scrollbar style="height: 418px; padding-right: 16px">
                  <div class="playlist-timeline">
                    <ul>
                      <li
                        v-for="time in 24"
                        :key="time.index"
                        :style="
                          time < 24
                            ? {
                                height: time_line_height + 'px',
                                position: 'relative',
                              }
                            : { height: 'initial' }
                        "
                      >
                        <div
                          v-show="timeSlider > 50 || minute === 1"
                          v-for="minute in 6"
                          :key="minute.index"
                          :style="{
                            height: time_line_height / 6 + 'px',
                            width: '100%',
                            textAlign: 'left'
                          }"
                        >
                          {{ getTimeText(time, minute) }}
                        </div>
                        <!-- <li v-for="minute in 5" :key="minute.index" :style=" { height: time_line_height / 5 + 'px' } "><span> {{ minute }} </span></li> -->
                      </li>
                    </ul>
                  </div>
                  <div class="playlist-events">
                    <ul>
                      <li
                        v-for="(item, index) in events"
                        :key="index"
                        class="playlist-event"
                        :style="{
                          top:
                            (time_line_height / 60) * item.startTime +
                            14 +
                            'px',
                          height:
                            (time_line_height / 60) *
                              (item.endTime - item.startTime) +
                            'px',
                        }"
                      >
                        <a
                          href=""
                          @click.prevent="onClickItem(index)"
                          :class="item.active"
                          :style="{ 'font-size': '3px' }"
                        >
                          <em class="playlist-event-name"> {{ item.text }} </em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
            <div class="mt-6 d-flex justify-center">
              <v-btn @click="dialog = true" text class="btn btn-s"
                >{{ $t("Playlist Add") }}
              </v-btn>
              <v-btn @click="deletePlaylist()" text class="btn btn-s ml-3"
                >{{ $t("Playlist Delete") }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="7"
            style="margin-left: 3%; max-width: calc(58.3333333333% - 3%)"
          >
            <div class="playlist-group-preview">
              <v-card flat class="video-preview video-detail">
                <v-card-title class="preview-normal-title font-weight-bold"
                  >{{$t('PLAYLIST DETAIL')}}</v-card-title
                >
                <v-card-text class="text-center pb-0 pt-3">
                  <div class="px-4">
                    <div class="img-preview">
                      <div
                        v-if="playlistPreview"
                        class="row"
                        style="width: 100%"
                      >
                        <div
                          v-for="(item, index) in selectItem.division.division"
                          :key="index"
                          :style="{ width: item + '%' }"
                        >
                          <!-- {{ contents.media_type }} -->
                          <img
                            v-if="
                              selectItem.division[index].length !== 0 &&
                              index === selectedContents.index
                            "
                            style="width: 100%; height: 50px"
                            :src="
                            selectItem.division[index].length === 0 ? 0 : srcURL + JSON.parse(selectItem.division[selectedContents.index][selectedContents.i].still_shot)[0]"
                          />
                          <img
                            v-else
                            style="width: 100%; height: 50px"
                            :src="
                            selectItem.division[index].length === 0 ? 0 : srcURL + JSON.parse(selectItem.division[index][0].still_shot)[0]"
                          />
                        </div>
                      </div>
                      <div v-else>{{$t('No Contents')}}</div>
                    </div>
                    <h6 class="caption text-left pt-6 pb-2">{{$t('Contents List')}}</h6>
                    <perfect-scrollbar style="height: 223px">
                      <div
                        v-if="selectItem.length === 0"
                        class="d-flex align-center justify-center"
                        style="height: 223px"
                      >
                        {{$t('No Contents')}}
                      </div>
                      <div
                        class="d-flex flex-column line-table"
                        v-if="isContents"
                      >
                        <div
                          v-for="(items, i) in totalItem"
                          :key="i"
                          class="d-flex flex-row line-table-row"
                        >
                          <div
                            v-for="(item, index) in contents.division"
                            :key="index"
                            class="d-flex line-table-item"
                            :style="
                              contents[index][i]
                                ? { width: item + '%' }
                                : { width: item + '%', height: '0px' }
                            "
                          >
                            <span
                              :class="
                                selectedContents.i === i &&
                                selectedContents.index === index
                                  ? 'width-full selected'
                                  : 'width-full'
                              "
                              @click="selectContentsIndex(index, i)"
                            >
                              {{
                                contents[index][i]
                                  ? contents[index][i].media_name
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="simple-line-table">
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr>
                                  <td>Dior_Lip_02</td>
                                  <td rowspan="6">NARSLIP_0101</td>
                                  <td>NARS_SS_LIP_</td>
                                </tr>
                                <tr>
                                  <td>Dior_Lip_03</td>
                                  <td>NARS_SS_LIP_...</td>
                                </tr>
                                <tr>
                                  <td>Dior_Lip_04</td>
                                  <td>NARS_SS_LIP_...</td>
                                </tr>
                                <tr>
                                  <td>Dior_Lip_05</td>
                                  <td>NARS_SS_LIP_...</td>
                                </tr>
                                <tr>
                                  <td>Dior_Lip_06</td>
                                  <td>NARS_SS_LIP_...</td>
                                </tr>
                                <tr>
                                  <td>Dior_Lip_07</td>
                                  <td>NARS_SS_LIP_...</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div> -->
                    </perfect-scrollbar>
                  </div>
                </v-card-text>
              </v-card>
              <div class="mt-4 d-flex justify-end px-4">
                <media-edit-dialog
                  @fireResetMedia="getMediaData()"
                  :isPlaylist="true"
                  :btnDisabledEditMedia="false"
                  :idx_media="idx_media"
                  :mediaType="media_type"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <!-- Playlist Add Dialog -->
    <v-dialog v-model="dialog" width="1080" @click:outside="closeDialog">
      <v-card class="popup add_store_popup">
        <h3 class="page-title-bar">
          <i class="ico ico-playlist-group"></i>{{ $t("Playlist Add") }}
        </h3>
        <v-card-text class="pa-0">
          <v-row no-gutters class="pb-2">
            <v-col cols="6">
              <div class="d-flex">
                <div style="width: 100%">
                  <v-autocomplete
                    :disabled="form.model === 29"
                    v-model="division.division"
                    :items="divisions"
                    :label="$t('Division')"
                    class="form-select form-active scrollBox"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </div>
                <!-- <div v-if="events.length < 1" class="d-inline-flex align-center ml-9"> -->
                <div class="d-inline-flex align-center ml-9">
                  <v-text-field
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    :label="$t('Time')"
                    @input="inputValue($event, 'startHour')"
                    v-model.number="times.startHour"
                    class="form-input w-65px text-center"
                    counter
                    maxlength="2"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                  <span class="pt-6 px-2">:</span>
                  <v-text-field
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    class="form-input w-65px text-center"
                    @input="inputValue($event, 'startMinute')"
                    v-model.number="times.startMinute"
                    counter
                    maxlength="2"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                  <span class="pt-6 px-4">~</span>
                  <v-text-field
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    class="form-input w-65px text-center"
                    @input="inputValue($event, 'endHour')"
                    v-model.number="times.endHour"
                    counter
                    maxlength="2"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                  <span class="pt-6 px-2">:</span>
                  <v-text-field
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    class="form-input w-65px text-center"
                    @input="inputValue($event, 'endMinute')"
                    v-model.number="times.endMinute"
                    counter
                    maxlength="2"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </div>
                <!-- <div v-else class="d-inline-flex align-center ml-9">
                    <v-text-field
                      :label="$t('Time')"
                      type="number"
                      max="23"
                      min="0"
                      v-model="temp_times.endHour"
                      class="form-input w-65px"
                      outlined
                      dense
                      hide-details>
                    </v-text-field>
                    <span class="pt-6 px-2">:</span>
                    <v-text-field
                      class="form-input w-65px"
                      type="number"
                      max="59"
                      min="0"
                      v-model="temp_times.endMinute"
                      outlined
                      dense
                      hide-details>
                    </v-text-field>
                  </div> -->
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-4 justify-space-between">
            <v-col cols="12"
              ><v-subheader class="sub-title2 px-0"
                >{{$t('Playlist Contents')}}</v-subheader
              ></v-col
            >
            <v-col cols="5">
              <v-layout wrap column>
                <v-row no-gutters>
                  <div class="playlist-cont-head">
                    <div
                      v-for="(item, index) in division.division"
                      :key="index"
                      :style="{ width: item + '%' }"
                    >
                      <div
                        v-if="division[index].length > 0"
                        :style="{
                          width: '100%',
                          height: '35px',
                          'background-image':
                            'url(' + getMediaPreview(division[index][0]) + ')',
                          'background-size': '100% 100%',
                        }"
                      />
                    </div>
                  </div>
                </v-row>
                <v-row no-gutters style="width: 100%">
                  <perfect-scrollbar style="width: 100%; height: 300px">
                    <div class="playlist-cont-body">
                      <div
                        v-for="(item, index) in division.division"
                        :key="index"
                        :style="{
                          width: item + '%',
                          height: '100%',
                          'min-height': '292px',
                        }"
                        :class="selectDivision === index ? 'active' : ''"
                        @click.self="onClickDivision(index)"
                      >
                        <ul @click.self="onClickDivision(index)">
                          <li
                            v-for="(media, i) in division[index]"
                            :key="i"
                            style="width: 90%"
                          >
                            <span style="text-align: center; width: 100%">
                              {{ media.media_name }}
                              {{
                                media.media_type === "image"
                                  ? media.duration + "s"
                                  : ""
                              }}
                            </span>
                            <v-btn
                              @click="deleteMedia(index, i)"
                              icon
                              x-small
                              color="#e27070"
                              ><v-icon>mdi-close-circle</v-icon></v-btn
                            >
                          </li>
                        </ul>
                      </div>
                      <!-- <div class="active">  -->
                    </div>
                  </perfect-scrollbar>
                </v-row>
              </v-layout>
            </v-col>
            <v-col
              cols="7"
              style="margin-left: 40px; max-width: calc(58% - 40px)"
            >
              <v-tabs
                v-model="tab"
                background-color="transparent"
                hide-slider
                center-active
                active-class="activeTab"
                class="tabs-type tabs-small"
              >
                <v-tab href="#tab-1st" class="primary--text">
                  <span>{{ $t("VIDEO") }}</span>
                </v-tab>
                <v-tab href="#tab-2nd" class="primary--text tab-divider">
                  <span>{{ $t("IMAGE") }}</span>
                </v-tab>
              </v-tabs>
              <v-card class="rounded-0 tabs-type-body">
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    :value="'tab-1st'"
                    :transition="false"
                    :reverse-transition="false"
                    v-if="tab === 'tab-1st'"
                  >
                    <v-card flat>
                      <v-card flat style="height: 317px">
                        <v-card-text>
                          <div class="simple-line-table horizontal-line mx-3">
                            <v-simple-table fixed-header height="286">
                              <template v-slot:default>
                                <colgroup>
                                  <col width="40%" />
                                  <col width="40%" />
                                  <col width="20%" />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>{{$t('Preview')}}</th>
                                    <th>{{$t('Title')}}</th>
                                    <th>{{$t('Add')}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, index) in videoData"
                                    :key="index"
                                  >
                                    <th v-if="index === 0">
                                      <div
                                        :style="{
                                          position: 'fixed',
                                          width: '155px',
                                          height: '200px',
                                          'background-image':
                                            'url(' + previewImg + ')',
                                          'background-size': 'contain',
                                          'background-position': 'center',
                                        }"
                                      ></div>
                                    </th>
                                    <th v-else></th>
                                    <td @click="mediaPreviewChange(item)">
                                      <div :style="{ 'max-width': '160px', 'text-overflow': 'ellipsis', overflow: 'hidden', 'white-space': 'nowrap' }"> {{ item.media_name }} </div>
                                    </td>
                                    <td>
                                      <v-btn
                                        icon
                                        x-small
                                        color="#000"
                                        @click="addContents(item, 'video')"
                                        ><v-icon>mdi-plus-circle</v-icon></v-btn
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    :value="'tab-2nd'"
                    :transition="false"
                    :reverse-transition="false"
                    v-if="tab === 'tab-2nd'"
                  >
                    <v-card flat>
                      <v-card flat style="height: 317px">
                        <v-card-text>
                          <div class="simple-line-table horizontal-line mx-3">
                            <v-simple-table fixed-header height="286">
                              <template v-slot:default>
                                <colgroup>
                                  <col width="40%" />
                                  <col width="25%" />
                                  <col width="20%" />
                                  <col width="10%" />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>{{$t('Preview')}}</th>
                                    <th>{{$t('Title')}}</th>
                                    <th>{{$t('Input Time')}}</th>
                                    <th>{{$t('Add')}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, index) in imageData"
                                    :key="index"
                                  >
                                    <th v-if="index === 0">
                                      <div
                                        :style="{
                                          position: 'fixed',
                                          width: '145px',
                                          height: '50px',
                                          'background-image':
                                            'url(' + previewImg + ')',
                                          'background-size': 'contain',
                                          'background-position': 'center',
                                        }"
                                      ></div>
                                    </th>
                                    <th v-else></th>
                                    <td @click="mediaPreviewChange(item)">
                                      <div :style="{ 'max-width': '100px', 'text-overflow': 'ellipsis', overflow: 'hidden', 'white-space': 'nowrap' }"> {{ item.media_name }} </div>
                                    </td>
                                    <td>
                                      <v-text-field
                                        v-model="item.duration"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        dense
                                        hide-details
                                      >
                                        <template v-slot:append-outer="">
                                          s
                                        </template>
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-btn
                                        icon
                                        x-small
                                        color="#000"
                                        @click="addContents(item, 'image')"
                                        ><v-icon
                                          style="transform: rotate(45deg)"
                                          >mdi-close-circle</v-icon
                                        ></v-btn
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon class="btn" @click="addPlaylist">{{
            $t("Add")
          }}</v-btn>
          <v-btn text icon @click="closeDialog" class="btn">{{
            $t("Close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="370">
        <v-card class="popup-success">
          <!-- <i class="ico-error"><img src="@/assets/img/ico-unassign.png" alt=""></i> -->
          <i class="ico-success"><img src="@/assets/img/ico-caution.png" alt=""/></i>
          <div class="msg">
              <!-- <h3>{{ $t('Delete') }}</h3> -->
              <p>{{ $t('Be careful as you are editing an active playlist') }}</p>
          </div>
          <v-card-actions class="d-flex justify-center btnArea">
              <v-btn text icon class="btn" @click="editPlaylist">{{$t('Yes')}}</v-btn>
              <v-btn text icon @click="confirmDialog = false" class="btn">{{$t('No')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import commons from '@/plugins/commons'
import MediaEditDialog from '@/lcd/views/Contents/modal/MediaEditDialog'

export default {
  name: 'EditPlaylistGroup',
  props: {
    index: Number,
    is_play: Boolean
  },
  components: {
    MediaEditDialog,
    PerfectScrollbar
  },
  data () {
    return {
      srcURL: dicamoApi.requests.thumbnail.url,
      previewImg: '',
      idx_media: '',
      media_type: '',
      form: {
        model: 23,
        title: '',
        category: '',
        data: []
      },
      selectedContents: {
        index: '',
        i: ''
      },
      times: {
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0
      },
      // temp_times: {
      //   startHour: 0,
      //   startMinute: 0,
      //   endHour: 0,
      //   endMinute: 0
      // },
      mediaDataList: null,
      events: [],
      videoData: [],
      imageData: [],
      options: {},
      totalProducts: null,
      division: {
        0: [],
        1: [],
        2: [],
        3: [],
        division: [100]
      },
      tab: null,
      dialog: false,
      videoItem: 1,
      value: 10,
      videoDuration: 0,
      timeSlider: 40,
      interval: null,
      selectDivision: 0,
      selectItem: [],
      selectIndex: 0,
      playlistItem: [],
      confirmDialog: false
    }
  },
  computed: {
    isContents () {
      let contents = false
      if (this.events.length > 0) contents = true
      return contents
    },
    contents () {
      return this.isContents ? this.events[this.selectIndex].division : []
    },
    totalItem () {
      if (this.isContents) {
        const array = []
        for (let i = 0; i < 4; i++) {
          array.push(this.contents[i].length)
        }
        return Math.max.apply(null, array)
      }
      return []
    },
    playlistPreview () {
      return commons.isNull(this.selectItem.length)
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    startTime () {
      return Number(this.times.startHour * 60 + this.times.startMinute * 1)
    },
    endTime () {
      return Number(this.times.endHour * 60 + this.times.endMinute * 1)
    },
    defaultModel () {
      return [
        {
          text: this.$t('Select model'),
          value: ''
        },
        {
          text: 'Newton Touch 23"',
          abbr: 'Newton Touch 23"',
          originalText: 'Newton Touch 23"',
          value: '23'
        },
        {
          text: 'Newton Touch 29"',
          abbr: 'Newton Touch 29"',
          originalText: 'Newton Touch 29"',
          value: '29'
        },
        {
          text: 'FHD Box',
          abbr: 'FHD',
          originalText: 'FHD',
          value: 'FHD'
        },
        {
          text: 'Tablet',
          abbr: 'Tablet',
          originalText: 'Tablet',
          value: 'Tablet'
        }
      ]
    },
    time_line_height () {
      return 30 + this.timeSlider * 2.4
    },
    editBtnDisabled () {
      return this.btnDisabledAddEditDelete
    },
    divisions () {
      return [
        {
          text: '1',
          value: [100]
        },
        {
          text: '1:1',
          value: [50, 50]
        },
        {
          text: '1:1:1:1',
          value: [25, 25, 25, 25]
        },
        {
          text: '2:1:1',
          value: [50, 25, 25]
        },
        {
          text: '1:2:1',
          value: [25, 50, 25]
        },
        {
          text: '1:1:2',
          value: [25, 25, 50]
        },
        {
          text: '3:1',
          value: [75, 25]
        },
        {
          text: '1:3',
          value: [25, 75]
        }
      ]
    }
  },
  mounted () {
    this.getPlaylistItem(this.index)
    this.getMediaData()
  },
  methods: {
    inputValue (value, type) {
      if (value > 23 && type === 'startHour') {
        this.times.startHour = 23
      }
      if (value > 60 && type === 'startMinute') {
        this.times.startMinute = 59
      }
      if (value > 23 && type === 'endHour') {
        this.times.endHour = 23
      }
      if (value > 60 && type === 'endMinute') {
        this.times.endMinute = 59
      }
    },
    mediaPreviewChange (item) {
      const preview = JSON.parse(item.still_shot)
      this.previewImg = this.srcURL + preview[0]
      // this.srcURL
    },
    getMediaPreview (item) {
      const preview = JSON.parse(item.still_shot)
      return this.srcURL + preview[0]
      // this.srcURL
    },
    selectContentsIndex (index, i) {
      this.selectedContents.index = index
      this.selectedContents.i = i
      this.idx_media = this.contents[index][i].idx_media
      this.media_type = this.contents[index][i].media_type
    },
    closeDialog () {
      this.dialog = false
      this.initialize()
    },
    getPlaylistItem (idxPlaylist) {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'item')
      data.append('idx_playlist_set', idxPlaylist)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getPlaylist.method,
          dicamoApi.requests.getPlaylist.url,
          data
        )
        .then((res) => {
          this.playlistItem = res.data.data

          this.form.title = this.playlistItem.title
          this.form.category = this.playlistItem.category
          this.form.model = this.playlistItem.device_type

          this.events = JSON.parse(this.playlistItem.playlist_set)
          this.events.forEach((item) => {
            item.active = ''
          })

          if (this.events.length > 0) {
            this.events[0].active = 'active'
            this.selectItem = this.events[0]
            this.temp_times = {
              startHour: this.events[0].endTime / 60,
              startMinute: this.events[0].endTime % 60,
              endHour: 0,
              endMinute: 0
            }
          }

          // this.getPlaylistData(res.data.data)
        })
        .catch((error) => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },

    editPlaylist () {
      const playlist = new FormData()

      const item = this.events

      item.sort(function (a, b) {
        if (a.startTime > b.startTime) {
          return 1
        }
        if (a.startTime < b.startTime) {
          return -1
        }
        return 0
      })

      this.events = item

      if (this.form.model === 29) {
        this.events.forEach((item) => {
          item.division[1] = []
          item.division[2] = []
          item.division[3] = []
          item.division.division = [100]
        })
      }

      playlist.append('company', this.$store.state.auth.user.company)
      playlist.append('store', this.$store.state.dataStore.selectedStore.code)
      playlist.append('device_type', this.form.model)
      playlist.append('title', this.form.title)
      playlist.append('category', this.form.category)
      playlist.append('playlist_set', JSON.stringify(this.events))
      playlist.append('idx_playlist_set', this.index)
      playlist.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.putPlaylist.method,
          dicamoApi.requests.putPlaylist.url,
          playlist
        )
        .then((res) => {
          if (res.data.result) {
            EventBus.$emit('messageAlert', this.$t('SUCCESS'))
            this.$router.push('Playlist')
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.message))
          }
        })
        .catch((error) => {
          console.debug(`Could not find any playlist. error: ${error}`)
        })

      // console.log(
      //   'store ' + this.$store.state.dataStore.selectedStore.code +
      //   '\ncompany ' + this.$store.state.auth.user.company +
      //   '\nmodel ' + this.form.model +
      //   '\ntitle ' + this.form.title +
      //   '\ncategory ' + this.form.category +
      //   '\ndata ' + JSON.stringify(this.events)
      // )
    },
    deletePlaylist () {
      this.events.splice(this.selectIndex, 1)
      // if (this.events.length !== 0) {
      //   const temp = this.events[this.events.length - 1].endTime
      //   console.log(temp)
      //   this.temp_times = {
      //     startHour: Math.floor(temp / 60),
      //     startMinute: temp % 60,
      //     endHour: 0,
      //     endMinute: 0
      //   }
      // }
      this.selectItem = []
      this.idx_media = ''
      this.selectedContents.i = 0
      this.selectedContents.index = 0
      this.selectIndex = 0
    },
    onClickItem (index) {
      this.selectItem = this.events[index]
      this.events.forEach((item) => {
        item.active = ''
      })
      if (this.selectItem.active === 'active') {
        this.selectItem.active = ''
      } else {
        this.selectItem.active = 'active'
      }
      this.selectIndex = index
      this.selectContentsIndex(0, 0)
    },
    eventTime (hour, minute) {
      if (minute > 59) {
        hour++
        minute -= 60
      }
      const time =
        (hour + '').padStart(2, '0') + ':' + (minute + '').padStart(2, '0')
      return time
    },
    initialize () {
      this.previewImg = ''
      this.selectDivision = 0
      // this.selectedContents.i = ''
      // this.selectedContents.index = ''
      this.times = {
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0
      }
      this.division = {
        0: [],
        1: [],
        2: [],
        3: [],
        division: [100]
      }
    },
    validate (start, end) {
      let state = false
      if (this.startTime <= start) {
        if (this.endTime >= start) {
          state = true
        }
      }
      if (this.startTime <= end) {
        if (this.endTime >= end) {
          state = true
        }
      }
      return state
    },
    addContents (item, type) {
      if (commons.isNull(this.selectDivision)) {
        EventBus.$emit('messageAlert', this.$t('Division Select First'))
        return
      }
      if (type === 'image') {
        if (Number(item.duration) < 10) {
          EventBus.$emit(
            'messageAlert',
            this.$t('Input the Image Duration (at least 10 seconds)')
          )
          return
        }
        if (Number(item.duration) > 120) {
          EventBus.$emit(
            'messageAlert',
            this.$t('Image Duration Error (max value 120 seconds)')
          )
          return
        }
      }
      this.division[this.selectDivision].push(item)
    },
    getTime (hour, minute) {
      return hour === 0 ? minute * 1 : hour * 60 + minute * 1
    },
    addPlaylist () {
      // if (this.events.length === 0) {
      if (this.endTime < this.startTime) {
        EventBus.$emit(
          'messageAlert',
          this.$t('End time cannot be less than start time')
        )
        return
      }
      if (this.endTime - this.startTime < 30) {
        EventBus.$emit(
          'messageAlert',
          this.$t('The minimum setting time is 30 minutes')
        )
        return
      }

      const count = this.division.division.length

      for (let i = 0; i < count; i++) {
        if (this.division[i].length === 0) {
          EventBus.$emit(
            'messageAlert',
            this.$t('Must add at least one media')
          )
          return
        }
      }

      const list = {
        active: false,
        division: this.division,
        startTime: this.startTime,
        endTime: this.endTime,
        text:
          this.eventTime(this.times.startHour, this.times.startMinute) +
          ' ~ ' +
          this.eventTime(this.times.endHour, this.times.endMinute)
      }
      if (list.endTime > 1440) {
        EventBus.$emit(
          'messageAlert',
          this.$t('The end time cannot exceed 24 hours')
        )
        return
      }
      let isTime = false
      this.events.forEach((item) => {
        if (item.endTime > this.startTime) {
          if (item.startTime < this.endTime) {
            EventBus.$emit('messageAlert', this.$t('already set time'))
            isTime = true
          }
        }
        if (item.startTime < this.endTime) {
          if (item.startTime > this.startTime) {
            EventBus.$emit('messageAlert', this.$t('already set time'))
            isTime = true
          }
        }
      })
      if (isTime) return
      this.events.push(list)
      // this.temp_times = {
      //   startHour: this.times.endHour,
      //   startMinute: this.times.endMinute,
      //   endHour: 0,
      //   endMinute: 0
      // }
      // }
      //  else {
      //   const list = {
      //     active: false,
      //     division: this.division,
      //     startTime: this.getTime(this.temp_times.startHour, this.temp_times.startMinute),
      //     endTime: this.getTime(this.temp_times.startHour, this.temp_times.startMinute) + this.getTime(this.temp_times.endHour, this.temp_times.endMinute),
      //     text: this.eventTime(this.temp_times.startHour, this.temp_times.startMinute) + ' ~ ' +
      //     this.eventTime(this.temp_times.startHour * 1 + this.temp_times.endHour * 1, this.temp_times.startMinute * 1 + this.temp_times.endMinute * 1)
      //   }
      //   console.log(list)
      //   if (list.endTime - list.startTime < 30) {
      //     EventBus.$emit('messageAlert', this.$t('The end time cannot exceed 24 hours'))
      //     return
      //   }
      //   this.temp_times = {
      //     startHour: Math.floor(list.endTime / 60),
      //     startMinute: list.endTime % 60,
      //     endHour: 0,
      //     endMinute: 0
      //   }
      //   if (list.endTime > 1440) {
      //     EventBus.$emit('messageAlert', this.$t('The end time cannot exceed 24 hours'))
      //     return
      //   } else {
      //     this.events.push(list)
      //   }
      //   console.log('is event')
      // }
      this.initialize()
      this.dialog = false
    },
    deleteMedia (index, i) {
      this.division[index].splice(i, 1)
    },
    cancelNewPlaylist () {
      this.$router.go(-1)
    },
    decrement () {
      this.timeSlider += 10
    },
    increment () {
      this.timeSlider -= 10
    },
    getTimeText (hour, minute) {
      return minute - 1 === 0 ? hour - 1 + 'h' : (minute - 1) * 10 + 'm'
    },
    editContents () {
      let mediaData = []
      this.mediaDataList.forEach((item) => {
        if (item.idx_media === this.idx_media) mediaData = item
      })

      this.events.forEach((item) => {
        for (let i = 0; i < 4; i++) {
          // for (let j = 0; j < item.division[i].length; j++) {
          //   if (Number(item.division[i][j].idx_media) === Number(this.idx_media)) {
          //     console.log('find item')
          //     console.log(item.division[i][j])
          //     item.division[i][j] = mediaData
          //   }
          // }
          item.division[i].forEach((media) => {
            if (Number(media.idx_media) === Number(this.idx_media)) {
              // media.media_name = mediaData.media_name
              Object.assign(media, mediaData)
            }
          })
        }
      })
    },
    onClickDivision (index) {
      if (this.selectDivision === index) {
        this.selectDivision = null
        return
      }
      this.selectDivision = index
      // commons.isNull(this.selectDivision)
    },
    getMediaData () {
      // if (commons.isNull(params.store)) return
      // if (this.productionType === dicamoApi.productionTypes.CLOUD) {
      //   params.company = this.$store.state.auth.user.company
      // }
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'list')
      form.append('limit', dicamoApi.EXPORT_SIZE)
      form.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getMedia.method,
          dicamoApi.requests.getMedia.url,
          form
        )
        .then((res) => {
          this.mediaDataList = res.data.data
          this.videoData = []
          this.imageData = []
          res.data.data.forEach((item) => {
            if (item.media_type === 'video') this.videoData.push(item)
            else this.imageData.push(item)
          })
          if (this.isContents) {
            this.editContents()
          }
        })
        .catch((error) => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          EventBus.$emit('messageAlert', this.$t('something went wrong'))
          console.debug(`Could not find any medias. error: ${error}`)
        })
    }
  },
  watch: {
    tab: {
      handler () {
        this.previewImg = ''
      }
    },
    form: {
      handler () {
        if (this.form.model === 29) {
          this.divison.division = [100]
        }
      }
    }
  }
}
</script>

<style scoped>
.selected {
  background-color: #7a7a7a;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  height: 37px;
}
.searchArea .col {
  padding: 0 12px !important;
}
.pageInfo {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.v-list {
  padding: 0;
  background: transparent;
}
.v-list-item {
  border-bottom: 1px solid #7a7a7a;
}
.v-list:hover {
  background-color: transparent;
}
.v-list-item-group .v-list-item--active {
  background-color: #031e37;
  color: #fff;
}

.video-preview .v-card__text {
  padding: 40px 0 62px;
}

::v-deep .v-list-item--link {
  background: #fff !important;
}

::v-deep .v-select__slot > input {
  color: white;
}

::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
::v-deep .v-input input::placeholder {
  color: #7a7a7a;
}
</style>
